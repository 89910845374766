@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbar styling */
.scrollbar::-webkit-scrollbar {
  display: block;
  width: 0.5rem;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ef8c3b;
  border-radius: 0.3125rem;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #ef8c3b;
  border-radius: 0.3125rem;
}
